import d1 from "./01.jpg";
import d2 from "./02.jpg";
import d3 from "./03.jpg";
import d4 from "./04.jpg";
import d5 from "./05.jpg";
import d6 from "./06.jpg";
import d7 from "./07.jpg";
import d8 from "./08.jpg";
import d9 from "./09.jpg";
import d10 from "./10.jpg";
import d11 from "./11.jpg";
import d12 from "./12.jpg";
import d14 from "./14.jpg";
import d15 from "./15.jpg";
import d16 from "./16.jpg";
import d16_1 from "./16_1.jpg";
import d17 from "./17.jpg";
import d18 from "./18.jpg";
import d18_1 from "./18_1.jpg";
import d19 from "./19.jpg";
import d20 from "./20.jpg";

export const diplomas = [
  d1,
  d17,
  d16,
  d16_1,
  d19,
  d2,
  d18, // add next page
  d18_1, // add next page
  d20,
  d3,
  d4,
  d5,
  d6,
  d7,
  d8,
  d9,
  d10,
  d11,
  d12,
  d14,
  d15,
];
