import React from "react";

const Card = ({ header, content, extra }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const cardRef = React.useRef(null);

  const handleToggleExpansion = () => {
    if (isExpanded && cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: "instant" });
    }
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <div className="Work-Card">
      <div className="Work-Card-Header" ref={cardRef}>
        <h3>{header}</h3>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        className="Work-Card-Text"
      />
      <div
        dangerouslySetInnerHTML={{
          __html: isExpanded
            ? extra
            : extra.split` `.slice(0, 60).join` ` + " ...",
        }}
      />
      <button onClick={handleToggleExpansion}>
        {isExpanded ? "Cкрыть" : "Подробнее"}
      </button>
    </div>
  );
};

export default Card;
