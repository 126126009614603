export const feedbackData = [
  {
    id: 2,
    text: `Работа с Анастасией помогла нашей семье преодолеть конфликты и улучшить взаимопонимание, хотя в терапии находилась только я. Несмотря на то, что я посещала психолога без мужа, я смогла оказывать влияние на всю нашу семью. Анастасия постоянно обучала меня конкретными инструментами для коммуникации и разрешения проблем, что сделало наши отношения в семье более гармоничными.`,
    sign: "Галина, 42 года",
    date: "15-04-2022",
  },
  {
    id: 3,
    text: `Недавно я обратилась за консультацией к Анастасии и осталась очень довольна, прямо счастлива. Мне все очень понравилось. Моя проблема, мучившая меня годами, была наконец-то решена. Я себя чувствую так легко! К моим чувствам и моему состоянию очень бережно отнеслись, при этом я вышла с решенным для себя вопросом. Я в восторге! От души благодарю Анастасию и очень рекомендую этого прекрасного специалиста!`,
    sign: "Наталья, 31 год",
    date: "07-09-2022",
  },
  {
    id: 4,
    text: 'Здравствуйте все! Хочу поделиться со всеми, у кого есть проблемы в семье или с собой как личность. Я столкнулась с тобой проблемой и обратилась к психологу. Психолог мне очень помогла разобраться с этими проблемами и я очень рада что обратилась именно к Насте. Она меня вытащила из "Ямы"с которой я не могла сама выбраться. И жизнь моя изменилась очень круто. Спасибо огромное за прекрасную работу 🤗🤗🤗и желаю каждому у кого в жизни что-то происходит не так, не откладывать на завтра а идти вперёд  и обращаться за помощью. Ещё раз огромное спасибо!',
    sign: "Оксана, 35 лет",
    date: "01-12-2022",
  },
  {
    id: 5,
    text: "Я обратилась к Анастасии в период сильного стресса в моей жизни, была сильная тревожность. Ее профессиональный и эмпатичный подход создал ощущение комфорта с первой сессии. Благодаря ее помощи, я научилась эффективным техникам управления тревожности и вернула в свою жизнь равновесие. Терапия длилась больше года, и я только с хорошими чувствами вспоминаю этот период.",
    sign: "Елена, 34 года.",
    date: "05-04-2023",
  },
  {
    id: 1,
    text: `Я попала на консультацию к Анастасии, и хочу сказать, что мне сказочно повезло.
      Во-первых, консультация прошла для меня очень результативно. Благодаря Анастасии я нашла ответ на вопрос, который очень давно не давал мне покоя, задерживал мое дальнейшее продвижение в профессиональном плане и мешал в отношениях с людьми.
      Во-вторых, Настя меня так внимательно выслушала и очень бережно отнеслась к моему состоянию. Я чувствовала заинтересованность к своей проблеме, сочувствие мне и по-человечески доброе отношение. Какое-то время назад я обращалась к другому психологу по рекомендации, где меня слушали с каменным беспристрастным лицом, мне было очень неуютно. У Анастасии совершенно другое дело, и к концу консультации я наконец-то стала спокойной и расслабленной (в противоположность невероятной какой-то напряженности, в которой я пришла, и непониманию, как мне уже отделаться от неприятных воспоминаний и выйти из создавшейся ситуации). А на следующий день я проснулась счастливая с приливом сил – это такое облегчение. Очень рекомендую Настю, этого прекрасного специалиста – вам и проблему помогут решить, и вы сами себя почувствуете, как будто вас (в смысле вашу душу) в теплое одеяло завернули и дали горячего чаю.`,
    sign: "Ирина, 33 года",
    date: "07-06-2023",
  },
  {
    id: 10,
    text: "Хочу выразить огромную благодарность психологу Анастасии за её профессионализм, чуткость и внимание. Обратившись к ней с некоторыми личными проблемами, я получила не только эффективные стратегии решения, но и поддержку на каждом этапе работы. Анастасия владеет глубоким пониманием человеческой психологии, что позволяет ей точно уловить корень проблемы и предложить индивидуальный подход к решению. Её тактичночть и понимание создают атмосферу доверия, в которой я чувствую себя комфортно и спокойно. Результаты работы с Анастасией превзошли мои ожидания, и я рада, что обратилась именно к ней за помощью. Рекомендую психолога Анастасию всем, кто ищет профессиональную поддержку и глубокое понимание своих проблем.",
    sign: "Илона, 30 лет",
    date: "15-08-2023",
  },
  {
    id: 6,
    text: "Получение от психолога Анастасии конкретных и практичных рекомендаций стало ключевым моментом в моей терапии. Она не только помогла мне понять корни моих проблем, но и предложила индивидуальные стратегии, которые я могла использовать в повседневной жизни. Было очень приятно работать с таким профессионалом.",
    sign: "Ирина, 40 лет.",
    date: "22-09-2023",
  },
  {
    id: 7,
    text: "Пришел к Анастасии по одному конкретному волнующему меня вопросу. Она проявила глубокое понимание к моей личности. Выслушала меня без осуждений и за 2 консультации помогла мне раскрыть аспекты моего характера, которые мешали моему личному и профессиональному росту. Эта работа над самопониманием привела к позитивным изменениям в моей жизни. Всем советую.",
    sign: "Сергей, 47 лет.",
    date: "3-10-2023",
  },
  {
    id: 8,
    text: "Анастасия была для меня настоящей опорой в период развода с бывшим мужем. Терапия длилась 1,5 года. Ее способность быстро анализировать ситуацию и предоставлять поддержку помогла мне пройти через все эти трудные моменты развода. Она не только слушала, но и помогала мне видеть пути решения проблем.",
    sign: "Ольга, 44 года.",
    date: "05-01-2024",
  },
  {
    id: 9,
    text: "Работа с этим психологом существенно повысила мою самооценку и самоуважение. Анастасия помогла мне разобраться в своих сильных сторонах и принять свои слабости. Благодаря этому, я стала более уверенной в себе и способной эффективно решать повседневные задачи. Также Анастасия помогла мне определиться с выбором Университета, проделав со мной диагностики.",
    sign: "Юлия, 19 лет.",
    date: "10-02-2024",
  },
];
