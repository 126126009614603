import Card from "./card";
import { content } from "./data";

import "./index.css";

const Work = () => {
  return (
    <div className="Work">
      <div className="Work-wrapper">
        <h2>С чем я работаю</h2>
        <div className="Work-content">
          {content.map(({ header, content, extra }, idx) => (
            <Card key={idx} header={header} content={content} extra={extra} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
