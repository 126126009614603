import React from "react";
import { feedbackData } from "./data";
import personIcon from "./person.png";
import starIcon from "./star.png";

import "./index.css";

const Feedback = () => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const buttonRef = React.useRef(null);

  const feedback = isExpanded ? feedbackData : feedbackData.slice(0, 4);

  const handleToggleExpansion = () => {
    if (isExpanded && buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: "instant" });
    }
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <div className="Feedback">
      <div className="Feedback-header">
        <h2>Отзывы</h2>
      </div>
      <div className="Feedback-section">
        {feedback.map(({ id, text, sign, date }) => {
          return (
            <div className="Feedback-block" key={id}>
              <div className="Feedback-author">
                <img alt="person icon" height={30} src={personIcon} />
                <span>{sign}</span>
              </div>
              <div>
                <img alt="rating star" height={25} src={starIcon} />
                <img alt="rating star" height={25} src={starIcon} />
                <img alt="rating star" height={25} src={starIcon} />
                <img alt="rating star" height={25} src={starIcon} />
                <img alt="rating star" height={25} src={starIcon} />
              </div>
              <div className="Feedback-content">
                <q>{text}</q>
              </div>
              <div className="Feedback-date">{date}</div>
            </div>
          );
        })}
      </div>
      <button onClick={handleToggleExpansion} ref={buttonRef}>
        {isExpanded ? "Меньше отзывов" : "Больше отзывов"}
      </button>
    </div>
  );
};

export default Feedback;
