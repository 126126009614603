import one from "./1.svg";
import two from "./2.svg";
import three from "./3.svg";
import four from "./4.svg";
import five from "./5.svg";
import logo from "./logo.jpg";

import "./index.css";

const Consultation = () => {
  return (
    <div className="Consultation">
      <div className="Consultation-block">
        <div className="Consultation-header">
          <h2>Порядок записи на консультацию</h2>
        </div>
        <div className="Consultation-content">
          <h3>Что нужно, чтобы попасть на консультацию?</h3>
          <img src={logo} alt="consultation sign-up" />
          <p>
            <img src={one} alt="One sign" />
            <span>
              Набраться смелости что-то изменить в вашей жизни и связаться со
              мной по предложенным контактам, либо отправить мне сообщение через
              форму обратной связи ниже.
            </span>
          </p>
          <p>
            <img src={two} alt="One sign" />
            <span>
              Консультация проходит в режиме видео звонка в Skype, WhatsApp или
              Telegram. В оговоренное время я буду ждать вашего звонка.
            </span>
          </p>
          <p>
            <img src={three} alt="One sign" />
            <span>
              Длительность консультации в зависимости от выбранного вами
              формата, составляет 60 или 90 минут.
            </span>
          </p>
          <p>
            <img src={four} alt="One sign" />
            <span>
              Для продуктивной работы, пожалуйста, обеспечьте свое личное время
              тишиной и спокойствием, хорошими наушниками и микрофоном. Было бы
              замечательно видеть Вас по пояс в сидячем положении.
            </span>
          </p>
          <p>
            <img src={five} alt="One sign" />
            <span>
              Более продуктивному использованию нашего рабочего времени
              способствует подготовленный вами запрос на решение проблемы.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Consultation;
