import logo from "./logo.jpg";
import phone from "./phone.png";
import instagram from "./instagram.svg";
import whatsapp from "./whatsapp.png";
import telegram from "./telegram.png";
import skype from "./skype.webp";
import email from "./email.png";

import "./index.css";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="Footer">
      <div className="Footer-block">
        <div className="Footer-row">
          <img
            className="logo"
            src={logo}
            alt="Anastasia Modina Phsicologist"
          />
          <div className="Footer-column">
            <h2>Психолог Анастасия Модина</h2>
            <p>+34 640 702 535</p>
            <div className="Footer-logoRow">
              <a
                href="tel:+34640702535"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={phone} alt="Phone logo" />
              </a>
              <a
                href="https://www.instagram.com/modinaanastasia"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="Instagram logo" />
              </a>
              <a
                href="https://wa.me/34640702535?text=Зравствуйте,%20я%20бы%20хотел(а)%20записаться%20на%20консультацию."
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={whatsapp} alt="Whatsapp logo" />
              </a>
              <a
                href="https://t.me/modinaspain?text=Зравствуйте,%20я%20бы%20хотел(а)%20записаться%20на%20консультацию."
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={telegram} alt="Telegram logo" />
              </a>
              <a href="skype:live:f58106692c88481d?chat">
                <img src={skype} alt="Skype logo" />
              </a>
              <a href="mailto: modinaanastasia@bk.ru">
                <img src={email} alt="E-Mail logo" />
              </a>
            </div>
          </div>
        </div>
        <div className="Footer-copyright">© {year} Модина Анастасия</div>
      </div>
    </div>
  );
};

export default Footer;
