import { useState, useEffect } from "react";
import up from "./up.png";

import "./index.css";

const ScrollToTop = () => {
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      setShouldShow(window.scrollY > 600);
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const shouldShowClass = shouldShow ? "show" : "";

  return (
    <img
      className={`Up-arrow ${shouldShowClass}`}
      onClick={scrollToTop}
      src={up}
      alt="Up arrow"
    />
  );
};

export default ScrollToTop;
