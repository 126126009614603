import { useEffect } from "react";
import navButton from "./next2.png";
import { diplomas } from "../About/data";

import "./index.css";

const Overlay = ({ src, onClick, setOverlaySrc }) => {
  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflowY = "hidden";
    }

    return () => {
      if (body) {
        body.style.overflowY = "auto";
      }
    };
  }, []);

  const idx = diplomas.indexOf(src);

  const navButtonHandler = (e, diff) => {
    e.stopPropagation();
    const nextIdx = idx + diff;
    if (nextIdx > -1 && nextIdx < diplomas.length) {
      setOverlaySrc(diplomas[nextIdx]);
    }
  };

  return (
    <div className="Overlay" onClick={onClick}>
      <img
        className="Overlay-button rotated"
        alt="navigation button"
        src={navButton}
        onClick={(e) => navButtonHandler(e, -1)}
        width={50}
      />
      <img
        className="Overlay-content"
        src={src}
        alt="Диплом Психолога Анастасия Модина"
      />
      <img
        className="Overlay-button"
        alt="navigation button"
        src={navButton}
        onClick={(e) => navButtonHandler(e, 1)}
        width={50}
      />
    </div>
  );
};

export default Overlay;
