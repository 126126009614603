import indShort from "./1876821.png";
import indLong from "./1470006.png";
import groupeLong from "./2519172.png";

import multind from "./multind.png";
import multlong from "./multlong.png";
import multfam from "./multfam.png";

import "./index.css";

const Prices = () => {
  return (
    <div className="Prices">
      <div className="Prices-block">
        <div className="Prices-header">
          <h2>Цены</h2>
        </div>
        <div className="Prices-content">
          <div className="Prices-card">
            <img src={indShort} alt="Individual consultation short" />
            <span>
              Индивидуальная консультация 60 минут – <strong>40 евро.</strong>
            </span>
          </div>
          <div className="Prices-card">
            <img src={indLong} alt="Individual consultation long" />
            <span>
              Индивидуальная консультация 90 минут – <strong>60 евро.</strong>
            </span>
          </div>
          <div className="Prices-card">
            <img src={groupeLong} alt="Groupe consultation long" />
            <span>
              Семейная консультация 90 минут – <strong>100 евро.</strong>
            </span>
          </div>
        </div>
        <h3>
          Если вы решили, что Я – именно «ваш» психолог, то вы можете приобрести
          пакет консультаций со скидкой:
        </h3>
        <div className="Prices-content">
          <div className="Prices-card">
            <img src={multind} alt="Individual consultation short" />
            <span>
              Пакет 10 консультаций по 60 минут – <strong>350 евро.</strong>
            </span>
          </div>
          <div className="Prices-card">
            <img src={multlong} alt="Individual consultation long" />
            <span>
              Пакет 10 консультаций по 90 минут – <strong>500 евро.</strong>
            </span>
          </div>
          <div className="Prices-card">
            <img src={multfam} alt="Groupe consultation long" />
            <span>
              Пакет 10 семейных консультаций – <strong>800 евро.</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prices;
