import { useForm } from "@formspree/react";
import logo from "./logo.jpg";
import done from "./done2.gif";

import "./index.css";

const EmailForm = ({ emailFormRef }) => {
  const [state, handleSubmit, reset] = useForm("moqbjwzw");

  const form = (
    <form className="EmailForm-form" onSubmit={handleSubmit}>
      <label htmlFor="phone">
        <strong>Ваш телефон (WhatsApp, Telegram):</strong>
      </label>
      <input id="phone" type="phone" name="phone" />
      <label htmlFor="message">
        <strong>Ваше сообщение:</strong>
      </label>
      <textarea id="message" name="message" />
      <button type="submit" disabled={state.submitting}>
        Отправить сообщение
      </button>
    </form>
  );

  const message = (
    <div className="EmailForm-message">
      <img src={done} alt="I am calling you back" />
      <p>
        <strong>
          Спасибо! Ваше сообщение отправлено, я свяжусь с вами в ближайшее
          время.
        </strong>
      </p>
      <button onClick={reset}>Отправить ещё одно сообщение</button>
    </div>
  );

  return (
    <div className="EmailForm" ref={emailFormRef}>
      <div className="EmailForm-block">
        <div className="EmailForm-header">
          <h2>Запись на консультацию</h2>
        </div>
        <div className="EmailForm-content">
          <div className="EmailForm-row">
            <p>
              Вы можете отправить мне сообщение и я свяжусь с вами для записи на
              консультацию или уточнения. Пожалуйста укажите ваше имя и контакты
              для связи с вами.
            </p>
            <img src={logo} alt="I am calling you back" />
          </div>
          {state.succeeded ? message : form}
        </div>
      </div>
    </div>
  );
};

export default EmailForm;
