import photo from "./logo.jpg";
import { diplomas } from "./data";

import "./index.css";

const About = ({ setOverlaySrc }) => {
  const imageOnClickHandler = (src) => {
    setOverlaySrc(src);
  };

  return (
    <div className="About">
      <div className="About-block">
        <div className="About-header">
          <h2>Обо мне</h2>
        </div>
        <div className="About-content">
          <img src={photo} alt="Психолог Анастасия Модина" />
          <p>
            Здравствуйте! Меня зовут Анастасия Модина, я - профессиональный
            психолог.
          </p>
          <p>
            На просторах интернета я именую себя Креативный психолог – ведь моя
            работа напрямую связана с творчеством. Каждый клиент для меня –
            бескрайний Мир, его душа – Вселенная возможностей и выборов, о
            которых он не догадывается или почему-то забыл.
          </p>
          <p>
            Проблемы клиента – галерея картин и этюдов: столько всего было
            изображено о его жизни! Я обучаю выбирать необходимый цвет и
            инструмент, стиль нанесения мазков и штрихов, чтобы созидать свою
            жизнь так, как нужно и хочется Тебе.
          </p>
          <p>
            Я работаю в интегративном подходе, сочетаю техники транзактного
            анализа, нейро-лингвистичечкого программирования, гипноза,
            арт-терапии и генеративного коучинга.
          </p>
          <p>
            Люблю давать домашние задания для клиентов. Заинтересована обучить
            самостоятельно справляться с жизненными трудностями и проблемами.
          </p>
          <h3>Мои дипломы:</h3>
          <div className="About-diploms">
            {diplomas.map((diploma, idx) => (
              <img
                key={`${diploma}-${idx}`}
                src={diploma}
                alt="Диплом Психолога Анастасия Модина"
                onClick={() => imageOnClickHandler(diploma)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
