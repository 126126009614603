import { useRef, useEffect } from "react";
import logo from "./logo.jpg";

import "./index.css";

const Welcome = ({ emailFormRef }) => {
  const pageHeader = useRef();

  useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current &&
          (pageHeader.current.style.transform =
            "translate3d(0," + windowScrollTop + "px,0)");
      };

      window.addEventListener("scroll", updateScroll);

      return () => {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, []);

  const consultationButtonHandler = () => {
    emailFormRef.current.scrollIntoView();
  };

  return (
    <div className="Welcome">
      <img className="Welcome-logo-desktop" src={logo} alt="Logo" />
      <div className="Welcome-content">
        <img className="Welcome-logo-mobile" src={logo} alt="Logo" />
        <h1>Анастасия Модина</h1>
        <h3>
          Ваш личный психолог <br /> в любой точке мира!{" "}
        </h3>
        <div className="Welcome-buttonsContainer">
          <button
            className="Welcome-consButton"
            onClick={consultationButtonHandler}
          >
            Записаться на консультацию
          </button>
        </div>
      </div>
      <div className="Welcome-back" ref={pageHeader} />
    </div>
  );
};

export default Welcome;
