import { useEffect, useRef, useState } from "react";

import Welcome from "./components/Welcome";
import Work from "./components/Work";
import Footer from "./components/Footer";
import About from "./components/About";
import Consultation from "./components/Consultation";
import Prices from "./components/Prices";
import EmailForm from "./components/EmailForm";
import ScrollToTop from "./components/ScrollToTop";
import Overlay from "./components/Overlay";
import Feedback from "./components/Feedback";

const App = () => {
  const [overlaySrc, setOverlaySrc] = useState("");

  const emailFormRef = useRef(null);

  useEffect(() => {
    setInterval(() => {
      const stripeIFrame = document.querySelector(
        "[name^=__privateStripeMetricsController]"
      );
      if (stripeIFrame) stripeIFrame.remove();
    }, 1000);
  }, []);

  const overlayOnclickHandler = () => {
    setOverlaySrc("");
  };

  return (
    <>
      <div className="App">
        <Welcome emailFormRef={emailFormRef} />
        <About setOverlaySrc={setOverlaySrc} />
        <Work />
        <Feedback />
        <Consultation />
        <Prices />
        <EmailForm emailFormRef={emailFormRef} />
        <Footer />
        <ScrollToTop />
      </div>
      {!!overlaySrc && (
        <Overlay
          src={overlaySrc}
          onClick={overlayOnclickHandler}
          setOverlaySrc={setOverlaySrc}
        />
      )}
    </>
  );
};

export default App;
